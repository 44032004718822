import React from 'react';
import {
  GoogleMaps,
  KeyValueList,
  PrimaryText,
  SimpleList,
} from '../components';
import grevToken from 'images/Properties/grevToken.svg';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import Disclaimer from '../components/Disclaimer';
// import ThirdImage from 'images/Properties/market/03.webp';

const Grev55Tab = (): JSX.Element => {
  const PrimaryTextContent = {
    aboutGrev55: {
      id: 1,
      title: '',
      anchor: 'about-grev55',
      content: [
        // eslint-disable-next-line quotes
        `Grev 55 property is located in a quiet area of Vejle city center on a beautiful hill with a private drive. The property has a distinct location from other properties on the same street, as GREV 55's unique location on the top of a hill displays a prime view of Vejle. The private driveway is shared with only one house and has parking options for up to 10 cars. A small river flows next to the street, and the area is dense with trees and rich with nature.`,
        'The center of the town with grocery stores, shops, a shopping center, a train and bus station, the main walking street, restaurants, nightlife, a hospital and educational institutions all within walking distance. The location makes it an attractive area for families and young professionals to rent. The nearest bus stop is located 1 minute away from the property. ',
        'The property is a free standing 5-apartment building complex. The building has three floors and a total gross area of 396 m² built on a 918 m² plot of land. The building was originally constructed in 1912, modernized several times, and will be renovated to current standards upon token issuance.',
        // eslint-disable-next-line quotes
        `The renovation consists of façade isolation, ensuring the highest thermalization and increasing the building's energy rating. The renovation will convert the current 3 apartments into 5 apartments, including a replacement of all the windows, refurbishment of the interior, and installation of new electrical, plumbing, and heating systems. The aim of the renovation is to modernize the building and improve its energy efficiency to today\'s standards, ensuring attractive, and cost-effective rental properties for future tenants.`,
        'As calculated by the Gross Rent Multiplier approach, the renovated property value will be around 2 € million. The estimated yearly rent collected will reach up to € 70,000 in the first year and will continue to follow market conditions. Tenants will pay utility and building maintenance bills on top of the rent.',
      ],
    },
  };

  const SimpleListContent = {
    quickFacts: {
      id: 1,
      title: 'Quick Facts',
      list: [
        'Attractive location for rental property',
        'Gross area of 396 m² with 918 m² plot of land',
        'The building will be renovated and modernized to highest standards',
        'Value increase will be ensured by increasing the number of apartments in the building and modernization of the building',
        'Investors will receive dividends from tenant rental payments',
      ],
    },
    keyDistances: {
      id: 2,
      title: 'Key Distances',
      list: [
        'Main walking street 700m',
        'Primary school 800m',
        'Grocery store 850m',
        'Hospital 1km',
        'High school 1.2km',
        'Vejle municipality 1.3km',
        'Train station 1.5km',
        'Shopping mall 1.7km',
      ],
    },
  };

  const KeyValueListContent = {
    aboutToken: {
      id: 1,
      title: '',
      rows: [
        {
          id: 1,
          key: 'GREV 55 token logo',
          value: grevToken,
        },
        {
          id: 2,
          key: 'Token name',
          value: 'GREV 55',
        },
        {
          id: 3,
          key: 'Location',
          value: 'Grejsdalsvej 55 ABCDE, Vejle, Denmark',
        },
        {
          id: 4,
          key: 'Area',
          value: 'Gross area 396 m² / Plot size 918 m²',
        },
        {
          id: 5,
          key: 'Year built',
          value: '1912',
        },
        {
          id: 6,
          key: 'Type',
          value: 'Renovation',
        },
        {
          id: 7,
          key: 'Number of apartments',
          value: '5',
        },
        {
          id: 8,
          key: 'Renovation period',
          value: '18 months',
        },
        {
          id: 9,
          key: 'Expected to be completed',
          value: 'Q1 2025',
        },
        {
          id: 10,
          key: 'Underlying asset value',
          value: '2.0 million Euro (for ready property) ',
        },
        {
          id: 11,
          key: 'Equity issuer',
          value: 'GREV 55 A/S CVR nr. 44047594',
        },
        {
          id: 12,
          key: 'Status',
          value: 'Equity financing / Digital Share Offering',
        },
        {
          id: 13,
          key: 'Equity offering amount',
          value: '80% for € 1.606.000',
        },
        {
          id: 14,
          key: 'Offering shares',
          value: 'Equity/non-voting ',
        },
      ],
    },
  };

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>GREV 55</TabTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          gap: '52px',
        }}
      >
        <Box>
          <PrimaryText content={PrimaryTextContent.aboutGrev55} />
        </Box>
        <Box>
          <KeyValueList content={KeyValueListContent.aboutToken} />
          <SimpleList content={SimpleListContent.quickFacts} />
          <SimpleList content={SimpleListContent.keyDistances} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GoogleMaps />
      </Box>
      <Disclaimer />
    </Container>
  );
};

export default Grev55Tab;
